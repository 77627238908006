<template>
  <div>
    <page-header
      :title="'Admins'"
      :sub-heading="true"
      :sub-header="'Dashboard'"
      :link-name="'Admin'"
      :link-url="'/admins'"
    >
        <ca-button @click.native="addRole" :type="'ghost'" class="mr-1">Create Roles</ca-button>
        <ca-button @click.native="addUser">Add User</ca-button>
    </page-header>
    
    <full-page-content-container>
       <div class="col-xs-12 pb">
        <div class="usp__nav tab-nav">
          <router-link
            :to="{name: 'admins'}"
            class="tab-nav__el"
            exact
            >Users
          </router-link>
          <router-link
            :to="{name: 'admins-roles'}"
            class="tab-nav__el"
            exact
            >Roles
          </router-link> 
        </div>
      </div>

      
      <ca-popup
        :header="'Delete User'"
        :toggle="openDeletePopup"
        @open="confirmDelete"
        @close="cancelDelete"
        v-show="currentRole != null"
      >
        <template v-slot:content>
          Are you sure you want to delete the user?
        </template>
        <template v-slot:actions>
          <ca-button @click.native="deleteRole()">Yes</ca-button>
        </template>
      </ca-popup>

      <grid-table :column-defs="columnDefs" :row-data="rowData" :fetching-data="fetchingData" v-if="rowData.length > 0">
        <template v-slot:cell-renderer="slotProps">
              <div>
                <span
                  class="edit-icon"
                  @click="edit(slotProps.currentRowData.id)"
                  ><img
                    :src="require('@/assets/images/icons/edit.svg')"
                    alt="edit icon"
                /></span>
                <span class="delete-icon"
                  @click="confirmDelete(slotProps.currentRowData)"
                  ><img
                    :src="require('@/assets/images/icons/delete.svg')"
                    alt="delete icon"
                /></span>
              </div>
            </template>
      </grid-table>
    </full-page-content-container>
  </div>
</template>

<script>
import axios from "../../../axios";
import PageHeader from "../../layouts/components/fullPage/PageHeader";
import FullPageContentContainer from "../../layouts/components/fullPage/FullPageContentContainer";
import CaButton from "../../components/buttons/CaButton";
// import subscriptios from "../../db/subscriptios";
import GridTable from "../../components/tables/GridTable";
import CaPopup from "../../components/popup/CaPopup";
export default {
  name: "Admins",
  components: { PageHeader, FullPageContentContainer, GridTable, CaButton, CaPopup },
  data() {
    return {
       columnDefs: [
        {
          headerName: "ID",
          field: "id",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Name",
          field: "name",
          filter: true,
          sortable: true,
        },
        {
          headerName: "No of Users",
          field: "user_count",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Permissions",
          field: "permissions_count",
          filter: true,
          sortable: true,
        },
         {
          headerName: "Aaction",
          field: "actions",
          hasCellRendererFramework: true,
        },
    
      ],
      rowData: [],
      fetchingData: false,
      cellRendererAction: "cell-renderer-action",
      currentRole: null,
      openDeletePopup: false,
    }
  },
  methods:{
    addUser(){
      this.$router.push({ name: 'admin-new'})
    },

    addRole(){
      this.$router.push({ name: 'add-role'})
    },
    edit(id){
      this.$router.push({ name: 'edit-role', params: { id: id }})
    },
    getUser(){
        const loading = this.$vs.loading();
      this.fetchingData = true;
      axios.defaults.headers.common["Authorization"] =
        this.$store.state.accessToken;
      axios
        .get(`${this.$store.state.server.requestUri}/admin/users`)
        .then((res) => {
          let data = res.data.data;
          data = data.filter( (admin) => {
            return admin.role !== 'Startup'
          })
          if (data.length > 0) {
            console.log(this.rowData);
            this.rowData = data;
          } else {
            console.log("No Admin data");
          }
          this.fetchingData = false;
          loading.close();
      })

    },

    getRoles(){
      const loading = this.$vs.loading();
      this.fetchingData = true;
      axios.defaults.headers.common["Authorization"] =
        this.$store.state.accessToken;
      axios
        .get(`${this.$store.state.server.requestUri}/admin/roles`)
        .then((res) => {
          let data = res.data.data;
          // console.log(data);
          if (data.length > 0) {
            axios
              .get(`${this.$store.state.server.requestUri}/admin/users`)
              .then((resp) => {
                let user = resp.data.data;
                user = user.filter( (admin) => {
                  return admin.role !== 'Startup'
                })
                this.rowData = data;
                axios
                  .get(`${this.$store.state.server.requestUri}/admin/permission`)
                  .then((response) => {
                    let permission = response.data.data;
                    this.rowData.forEach( (role) => {
                      role.users = user.filter( (admin) => {
                        return admin.role === role.name
                      })
                      role.user_count = role.users.length;
                      role.permissions = permission.filter( (permission) => {
                        return permission.role_name === role.name
                      })
                      role.permissions_count = role.permissions.length;
                    })
                    this.fetchingData = false;
                    loading.close();
                })
         

                
              // console.log(this.rowData);
              // const user_count = role_users.length;
              // this.rowData.user_count = user_count;
              // console.log(user);
            })
            
          } else {
            console.log("No Admin data");
          }
    
        })

    },
    
    cancelDelete() {
      this.openDeletePopup = false;
    },
    confirmDelete(popUpData = null) {
      if (popUpData !== null) {
        this.currentRole = popUpData;
      }
      console.log(this.currentRole);
      this.openDeletePopup = true;
    },
    deleteRole() {
      console.log(this.currentUser);
      // this.cancelDelete();
      const loading = this.$vs.loading();
      axios
        .delete(
          `${this.$store.state.server.requestUri}/admin/roles/${this.currentRole.id}`
        )
        .then((res) => {
          loading.close();
          this.$toast(
            `Role with id ${this.currentRole.id} deleted successfully`,
            {
              position: "top-center",
              type: "success",
            }
          );
          console.log(res);
        })
        .catch((err) => {
          loading.close();
          this.$toast(`An error occurred while deleting user`, {
            position: "top-center",
            type: "error",
          });
          console.log(err.response);
        });
    },
  },
  created() {
    // this.rowData = subscriptios;
    this.getRoles();

  },
};
</script>

<style scoped>
.pb{
  padding-bottom: 2em;
}
.edit-icon {
  margin-right: 0.8rem;
}

span:hover {
  cursor: pointer;
}
</style>
